/* Custom style for page content */
.page-content {
  @apply max-w-screen-md mx-auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-4;
  }
  p {
    @apply my-2;
  }

  em {
    @apply text-gray-600 italic;
  }
  ul {
    list-style: square;
    padding: 0.25rem 0 0 2.25rem;
  }
  ol {
    list-style: decimal;
    padding: 0.25rem 0 0 2.25rem;
  }
  blockquote {
    padding: 0 1em;
    color: gray;
    border-left: 0.25em solid gray;
    margin: 1rem 0;
  }
  pre {
    padding: 1rem;
  }
  a {
    @apply text-primary;
  }
}
